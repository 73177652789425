import eventsHandler from '@/services/common/eventsHandler.js'

class invoiceForm extends eventsHandler {
  constructor() {
    super()
    this._invoice = {}
    this._valid = false
  }

  get invoice() {
    const invoice = {
      ...this._invoice
    }
    return invoice
  }

  get valid() {
    return this._valid
  }

  get isNew() {
    return !this._invoice.id
  }

  setValid(valid) {
    this._valid = valid
    this._executeCallbacksOf('valid-change', valid)
  }

  resetInvoice() {
    this._invoice = {}
    this._executeCallbacksOf('reset')
    this._executeCallbacksOf('update', {invoice: {}})
  }

  updateField(key, value) {
    this._invoice[key] = value
    this._executeCallbacksOf('update', {invoice: this._invoice})
    this._executeCallbacksOf('update-' + key, {key: this._invoice[key]})
    this._executeCallbacksOf('update-is-new', this.isNew)
  }

  updateFields(keys, values) {
    let i = 0
    for (const key of keys){
      this._invoice[key] = values[i]
      i++
      this._executeCallbacksOf('update-' + key, {key: this._invoice[key]})
    }
    this._executeCallbacksOf('update', {invoice: this._invoice})
    this._executeCallbacksOf('update-is-new', this.isNew)
  }

  updateObject(invoice) {
    this._invoice = invoice
    this._executeCallbacksOf('update', {invoice: this._invoice})
    this._executeCallbacksOf('update-is-new', this.isNew)
  }
}

export default new invoiceForm()
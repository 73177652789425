import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js'
import _ from "lodash";
import DateHandler from "@/services/common/dateHandler";

class invoicesManagment {
  list(page, rowPerPage, filters) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/payments/invoices/list", {
          page: page,
          rowPerPage: rowPerPage,
          filters: filters,
        })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(response.results);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  create(invoice) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/payments/invoices/create", invoice)
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(response.results);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getLastInternalCode() {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/payments/invoices/getLastInternalCode", {})
        .then((response) => {
          if (response.success) {
            resolve(response.results[0].max);
          }
        });
    });
  }

  getLastInvoice() {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/payments/invoices/getLastInvoice", {})
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          }
        });
    });
  }

  getLastCode(type) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/payments/invoices/getLastCode", { type: type })
        .then((response) => {
          if (response.success) {
            resolve(response.results[0].max);
          }
        });
    });
  }

  update(invoice) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/payments/invoices/update", invoice)
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(response.results);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  get(invoiceId) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .get(apiUrl + "/payments/invoices/get", { id: invoiceId })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(response.results);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  archive(invoice) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!invoice && !invoice.id) {
        reject(new Error("invoice is not specified"));
        return;
      }

      axios
        .post(apiUrl + "/payments/invoices/archive", invoice)
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(response.results);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  pay(invoice) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!invoice && !invoice.id) {
        reject(new Error("invoice is not specified"));
        return;
      }

      axios
        .post(apiUrl + "/payments/invoices/pay", invoice)
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(response.results);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /** 
    * @param {number} invoiceId - id of invoice 
    * @param {Object[]} items - Array of elements to store in DB 
    * @param {number} items[].amount  
    * @param {Date} items[].expireDate  
    * @param {string} items[].type  
  */
  async schedulePayments(invoiceId, items) {
    try {
      const apiUrl = UrlKeeper.getUrl()
      const results = await axios
        .post(apiUrl + "/payments/invoices/schedulePayments", {
          invoiceId: invoiceId,
          items: items
        })
      return results
    } catch (error) {
      throw new Error(error)
    }
  }

  createFromOrder(invoice, supplier, order, items) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!supplier && !supplier.id) {
        reject(new Error("supplier is not specified"));
        return;
      }

      if (!order && !order.id) {
        reject(new Error("order is not specified"));
        return;
      }

      // if (items.length == 0 || items.some((el) => !Number(el.id))) {
      //   reject(new Error("items is not in the right form"));
      //   return;
      // }
      axios
        .post(apiUrl + "/payments/invoices/createFromOrder", {
          invoice: invoice,
          supplier: supplier,
          order: order,
          items: items,
        })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(response.results);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  createAndAddCustomer(invoice, customer) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/payments/invoices/createAndAddCustomer", {
          invoice: invoice,
          customer: customer,
        })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(response.results);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  createAndAddSupplier(invoice, supplier) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/payments/invoices/createAndAddSupplier", {
          invoice: invoice,
          supplier: supplier,
        })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(response.results);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  //#region  InvoiceItems
  itemList(invoice) {
    return new Promise((resolve, reject) => {
      this.get(invoice, "item")
        .then((results) => {
          if (!results.invoiceItems) {
            reject(new Error("invoice items not defined"));
          } else {
            resolve(results.invoiceItems);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  addItems(invoice, items) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!invoice || !invoice.id) {
        reject(new Error("invoice is not defined"));
        return;
      } else if (!items || !items.length > 0) {
        reject(new Error("items is not defined"));
        return;
      }

      axios
        .post(apiUrl + "/payments/invoices/" + invoice.id + "/items/add", items)
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(new Error(response.results));
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateInvoiceItem(invoice, invoiceItemId, params) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!invoice || !invoice.id) {
        reject(new Error("invoice is not defined"));
        return;
      }

      axios
        .post(
          apiUrl + "/payments/invoices/" + invoice.id + "/items/update",
          {
            invoiceItemId: invoiceItemId,
            params: params,
          }
        )
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(new Error(response.results));
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  addItemToInvoice(invoice, item) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!invoice || !invoice.id) {
        reject(new Error("invoice is not defined"));
        return;
      } else if (!item || !item.id) {
        reject(new Error("item is not defined"));
        return;
      }

      axios
        .post(apiUrl + "/payments/invoices/" + invoice.id + "/items/add", item)
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(new Error(response.results));
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  removeItemFromInvoice(invoice, item) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!invoice || !invoice.id) {
        reject("invoice is not specified");
        return;
      } else if (!item || !item.id) {
        reject("item is not specified");
        return;
      }

      axios
        .post(
          apiUrl + "/payments/invoices/" + invoice.id + "/items/remove",
          item
        )
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(new Error(response.results));
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  deleteItemFromInvoice(invoice, item) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!invoice || !invoice.id) {
        reject("invoice is not specified");
        return;
      } else if (!item || !item.id) {
        reject("item is not specified");
        return;
      }

      axios
        .post(
          apiUrl + "/payments/invoices/" + invoice.id + "/items/delete",
          item
        )
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(new Error(response.results));
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  //#endregion

  //#region  InvoiceServices

  serviceList(invoice) {
    return new Promise((resolve, reject) => {
      this.get(invoice, "service")
        .then((results) => {
          if (!results.invoiceServices) {
            reject(new Error("invoice services not defined"));
          } else {
            resolve(results.invoiceServices);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateInvoiceService(invoice, invoiceServiceId, params) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!invoice || !invoice.id) {
        reject(new Error("invoice is not defined"));
        return;
      }
      axios
        .post(
          apiUrl + "/payments/invoices/" + invoice.id + "/services/update",
          {
            invoiceServiceId: invoiceServiceId,
            params: params,
          }
        )
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(new Error(response.results));
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  addServices(invoice, services) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!invoice || !invoice.id) {
        reject(new Error("invoice is not defined"));
        return;
      } else if (!services || !services.length > 0) {
        reject(new Error("services is not defined"));
        return;
      }

      axios
        .post(
          apiUrl + "/payments/invoices/" + invoice.id + "/services/add",
          services
        )
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(new Error(response.results));
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  addServiceToInvoice(invoice, service) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!invoice || !invoice.id) {
        reject(new Error("invoice is not defined"));
        return;
      } else if (!service || !service.id) {
        reject(new Error("service is not defined"));
        return;
      }

      axios
        .post(
          apiUrl + "/payments/invoices/" + invoice.id + "/services/add",
          service
        )
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(new Error(response.results));
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  removeServiceFromInvoice(invoice, service) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!invoice || !invoice.id) {
        reject("invoice is not specified");
        return;
      } else if (!service || !service.id) {
        reject("service is not specified");
        return;
      }

      axios
        .post(
          apiUrl + "/payments/invoices/" + invoice.id + "/services/remove",
          service
        )
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(new Error(response.results));
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  deleteServiceFromInvoice(invoice, service) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!invoice || !invoice.id) {
        reject("invoice is not specified");
        return;
      } else if (!service || !service.id) {
        reject("service is not specified");
        return;
      }

      axios
        .post(
          apiUrl + "/payments/invoices/" + invoice.id + "/services/delete",
          service
        )
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(new Error(response.results));
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  //#endregion

  //#region InvoiceGenericElements

  genericElementList(invoice) {
    return new Promise((resolve, reject) => {
      this.get(invoice, "genericElement")
        .then((results) => {
          if (!results.invoiceGenericElements) {
            reject(new Error("invoice genericElements not defined"));
          } else {
            resolve(results.invoiceGenericElements);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  addGenericElements(invoice, genericElements) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!invoice || !invoice.id) {
        reject(new Error("invoice is not defined"));
        return;
      } else if (!genericElements || !genericElements.length > 0) {
        reject(new Error("genericElements is not defined"));
        return;
      }

      axios
        .post(
          apiUrl + "/payments/invoices/" + invoice.id + "/genericElements/add",
          genericElements
        )
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(new Error(response.results));
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  addGenericElementToInvoice(invoice, genericElement) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!invoice || !invoice.id) {
        reject(new Error("invoice is not defined"));
        return;
      }

      axios
        .post(
          apiUrl + "/payments/invoices/" + invoice.id + "/genericElements/add",
          genericElement
        )
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(new Error(response.results));
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  removeGenericElementFromInvoice(invoice, genericElement) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!invoice || !invoice.id) {
        reject("invoice is not specified");
        return;
      } else if (!genericElement || !genericElement.id) {
        reject("genericElement is not specified");
        return;
      }

      axios
        .post(
          apiUrl +
          "/payments/invoices/" +
          invoice.id +
          "/genericElements/remove",
          genericElement
        )
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(new Error(response.results));
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  deleteGenericElementFromInvoice(invoice, genericElement) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!invoice || !invoice.id) {
        reject("invoice is not specified");
        return;
      } else if (!genericElement || !genericElement.id) {
        reject("genericElement is not specified");
        return;
      }

      axios
        .post(
          apiUrl +
          "/payments/invoices/" +
          invoice.id +
          "/genericElements/delete",
          genericElement
        )
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(new Error(response.results));
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateInvoiceGenericElement(invoice, invoiceGenericElementId, params) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!invoice || !invoice.id) {
        reject(new Error("invoice is not defined"));
        return;
      }
      axios
        .post(
          apiUrl + "/payments/invoices/" + invoice.id + "/genericElements/update",
          {
            invoiceGenericElementId: invoiceGenericElementId,
            params: params,
          }
        )
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(new Error(response.results));
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  addPaymentTransactionToInvoice(invoice, paymentTransaction) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!invoice || !invoice.id) {
        reject(new Error("invoice is not defined"));
        return;
      }

      axios
        .post(
          apiUrl + "/payments/invoices/" + invoice.id + "/paymentTransaction/add",
          paymentTransaction
        )
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(new Error(response.results));
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  //#endregion

  listType() {
    return [
      { name: "Salone", value: "saloon" },
      { name: "Ristorante", value: "restaurant" },
      { name: "Altro", value: "other" },
    ];
  }

  fields() {
    return Promise.resolve([
      { text: "Privato/Azienda", value: "customer", type: "custom" },
      { text: "Codice interno", value: "internalCode" },
      { text: "Numero Fattura o Documento", value: "documentNumber" },
      { text: "Ammontare", value: "amount", type: "custom" },
      { text: "Stato", value: "status", type: "custom" },
      { text: "Tipo", value: "type", type: "custom" },
      { text: "Intestata a", value: "emittedTo", type: "custom" },
      { text: "Emessa il", value: "emittedAt", type: "datetime" },
      { text: "Data di scadenza", value: "expirationDate", type: "date" },
      { text: "N° Rate", value: "paymentCredits", type: "custom" },
      { text: "Residuo Rate", value: "remainingPaymentCredits", type: "custom" }
    ]);
  }
  _fieldsToExport() {
    return {
      "Privato/Azienda": "fullname",
      "Codice interno": "internalCode",
      "Numero Fattura o Documento": "documentNumber",
      "Ammontare": {
        field: "amount",
        callback: (amount) => {
          return Number(amount).toFixed(2).replace('.', ',');
        }
      },
      "Stato": "status",
      "Stato": {
        field: "status",
        callback: (status) => {

          let statuses = [
            { key: "pending", text: "Fattura da pagare" },
            { key: "pending_warehouse", text: "Pagamento da effettuare" },
            { key: "paid_warehouse", text: "Pagata" },
            { key: "paid_restaurant", text: "Incassata" },
            { key: "paid_saloon", text: "Incassata" },
            { key: "paid_cashDesk", text: "Incassata" },
            { key: "cashed", text: "Incassata" },
            { key: "paid", text: "Pagata" },
            { key: "cancelled", text: "Cancellata" },
            { key: "beingPaid", text: "Pagamento a rate" },
          ]

          let findedStatus = statuses.find(z => z.key == status)
          return !!findedStatus ? findedStatus.text : ""
        },
      },
      "Tipo": {
        field: "type",
        callback: (type) => {
          let types = [
            { key: "warehouse", text: "Automatica magazzino" },
            { key: "restaurant", text: "Manuale ristorante" },
            { key: "other", text: "Altro" },
            { key: "cashDesk", text: "Automatica in cassa" },
            { key: "migration", text: "Automatica da migrazione" },
            { key: "otherToPay", text: "Manuale" },
            { key: "otherToCash", text: "Manuale" }

          ]
          let findedType = types.find(z => z.key == type)
          return !!findedType ? findedType.text : ""
        },
      },
      "Intestata a": "emittedTo",
      "Emessa il": {
        field: "emittedAt",
        callback: (value) => {
          return DateHandler._buildDateToExport(`${value}`, true);
        },
      },
      "Data di scadenza": {
        field: "expirationDate",
        callback: (value) => {
          return DateHandler._buildDateToExport(`${value}`);
        },
      },
      "N° Rate": {
        field: "paymentCredits", 
        callback: (value) => {
          return value.length
        }
      },
      "Residuo Rate": {
        callback: (value) => {
          return value.paymentCredits.filter(el => el.status == 'beginning').reduce((acc, cur) => {
            return acc + Number(cur.amount)
          }, 0).toFixed(2).replace('.', ',')
        }
      }
    };
  }


}

export default new invoicesManagment();
